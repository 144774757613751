<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    "$i18n.locale"(val, oldVal) {
      localStorage.setItem("fedExlocale", val);
    }
  },
  created() {
    this.initLang()
  },
  methods: {
    initLang() {
      if (localStorage.getItem("fedExlocale")) {
        let localeStr = localStorage.getItem("fedExlocale");
        this.$i18n.locale = localeStr;
      } else {
        let language = navigator.language.slice(0, 2);
        let hasLang = [];
        for (let lang in this.$i18n.messages) {
          hasLang.push(lang);
        }

        let localeStr;
        if (hasLang.indexOf(language) !== -1) {
          localeStr = language;
        } else {
          localeStr = "en";
        }
        this.$i18n.locale = localeStr;
      }
    },
  }
}
</script>

<style lang="scss">


body {
  font-family: "FedExSans_Lt", sans-serif;
  /* 应用自定义字体样式到 body 元素 */
}

body {
  padding: 0;
  margin: 0;
}

#app {
  //width: 100vw;
	width: 100%;
	max-width: 100vw;
  height: 100vh;
  min-width: 960px;
  min-height: 540px;
  //overflow: hidden;
}

.el-card__body {
  box-sizing: border-box;
}

.el-card__body>div {
  box-sizing: border-box;
}</style>
