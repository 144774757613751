const state = {
    activeMenu: '', // 当前激活的导航
}

const mutations = {
    SET_ACTIVE_MENU: (state, idx) => {
        state.activeMenu = idx
    },
}

const actions = {
    setActiveMenu({ commit }, idx){
        commit('SET_ACTIVE_MENU', idx)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
