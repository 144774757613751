export default {
    fedEx: {
        realTime: {
            title: "Loop Sort Real-time Dashboard",
            No: "No.",
            barCode: "Barcode",
            weight: "Weight(kg)",
            length: "Length(mm)",
            width: "Width(mm)",
            height: "Height(mm)",
            cubic: "Cubic Volume(mm³)",
            time: "Time",
            weight2: "Weight",
            cubic2: "Cubic Volume",
            totalNumber: "Total Number of Barcode",
            efficiency: "Efficiency(Pcs/h)",
            hightest: "Highest",
            realtime: "Real-time"
        },
        period: {
            title: "Loop Sort Periodic Report",
            HandledPcs: "Handled Pcs",
            HandledWeight: "Handled Weight(kg)",
            PAKAverage: "PAK Average Weight(kg)",
            BoxAverage: "Box Average Weight(kg)",
            NetEfficiency: "Net Efficiency (pcs/h)",
            GrossEfficiency: "Gross Efficiency (pcs/h)",
            BarcodeReadRate: "Barcode Read Rate",
            DimReadRate: "Dim-weight Read Rate",
            ExceptionRate: "Sorting Exception Rate",
            AveragePcs: "Average Pcs/Bag",
            DataException: "Data Exception",
            carUsageRate: "Car Usage Rate",
            Sort: "Sort",
            LinearSort: "Linear Sort",
            LoopSort: "Loop Sort",
            ManualSort: "ManualSort",
            Total: "Total",
            Pcs: "Pcs",
            Weight: "Weight(KG)",
            TotalHandledPcs: "Total Handled Pcs",
            TotalHandledWeight: "Total Handled Weight(KG)",
            AveragePcsBag: "Average Pcs/Bag",
            AveragePackageWeight: "Average Package Weight(KG)",
            Week: "Weekly Summary",
            Month: "Monthly Summary",
            Year: "Annual Summary"
        },
        period2: {
            TruckNo: "Truck No.",
            TruckCons: "Truck Cons",
            PackageType: "Package Type",
            Pcs: "Pcs",
            ArrivalTime: "Truck Arrival Time",
            StartTime: "Truck Sorting Start Time",
            EndTime: "Truck Sorting End Time",
            DataException: "Data Exception",
            Recorded: "Recorded",
            NoRecord: "No Record",
            Ratio: "Ratio",
            WeightVolumeException: "Weight & Cubic Volume Exception",
            Weight: "Weight",
            CubicVolume: "Cubic Volume",
            SortingExceptionRatio: "Sorting Exception Ratio",
            SystemDefault: "System Default",
            ManualPause: "Manual Pause",
            Timeout: "Timeout",
            Others: "Others",
            Secondary: "Secondary",
            BarcodeReadRate: "Barcode Read Rate",
            Standard: "Standard"
        }
    }
}