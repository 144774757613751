<template>
  <div :id="uuid" :style="style2" />
</template>

<script>
import * as echarts from 'echarts'
import resize from '@/utils/mixins/resize'
export default {
  mixins: [resize],
  props: {
    height: { // echarts的高度
      type: String,
      default: '400px'
    },
    width: { // echarts的宽度
      type: String,
      default: '600px'
    },

    options: { // echarts的的配置
      type: Object,
      default: null
    },

    theme: { // echarts的主题
      type: Object,
      default: null
    },

    themeName: { // echarts的主题名称
      type: String,
      default: ''
    }
  },

  data() {
    return {
      uuid: null,
      chart: null
    }
  },

  computed: {
    style2() {
      return {
        height: this.height,
        width: this.width
      }
    }
  },

  watch: {
    width(a, b) {
      if (this.chart) {
        // 这里需要异步才能生效
        const time = setTimeout(() => {
          clearTimeout(time)
          this.chart.resize({
            animation: {
              duration: 500
            }
          })
        }, 0)
      }
    },

    options: {
      handler(newVal) {
        if (this.chart) {
          // notMerge 可选。是否不跟之前设置的 option 进行合并。默认为 false。即表示合并。
          // 合并的规则，详见 组件合并模式。如果为 true，表示所有组件都会被删除，然后根据新 option 创建所有新组件
          this.chart.setOption(this.options, {
            notMerge: true
          })
        }
      },
      immediate: true,
      deep: true
    }
  },

  created() {
    this.uuid = this.createUuid(32)
  },

  mounted() {
    if (this.theme && this.themeName) { // 更换主题
      echarts.registerTheme(this.themeName, this.theme)
      this.chart = echarts.init(document.getElementById(this.uuid), this.themeName)
    } else {
      // 准备实例
      this.chart = echarts.init(document.getElementById(this.uuid))
    }

    // 应用配置项
    this.chart.setOption(this.options, {
      notMerge: true
    })
  },

  methods: {
    // 创建uuid
    createUuid(len, radix) {
      const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
      const uuid = []; let i
      radix = radix || chars.length

      if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
      } else {
        // rfc4122, version 4 form
        let r

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | Math.random() * 16
            uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
          }
        }
      }

      return uuid.join('')
    }
  }
}
</script>
