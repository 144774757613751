export default {
    fedEx: {
        realTime: {
            title: "环形线实时看板",
            No: "序号",
            barCode: "条码",
            weight: "重量(kg)",
            length: "长(mm)",
            width: "宽(mm)",
            height: "高(mm)",
            cubic: "体积(mm³)",
            time: "时间",
            weight2: "重量",
            cubic2: "体积",
            totalNumber: "条码总数",
            efficiency: "效率(件/h)",
            hightest: "最高",
            realtime: "实时"
        },
        period: {
            title: "环形线阶段看板",
            HandledPcs: "过机件数",
            HandledWeight: "过机重量(KG)",
            PAKAverage: "PAK平均重量(KG)",
            BoxAverage: "BOX平均重量(KG)",
            NetEfficiency: "净效率(pcs/h)",
            GrossEfficiency: "毛净效率(pcs/h)",
            BarcodeReadRate: "条码读取率",
            DimReadRate: "重量/体积读取率",
            ExceptionRate: "Belt尾货占比",
            AveragePcs: "平均每袋件数",
            DataException: "异常数据件数",
            carUsageRate: "小车利用率",
            Sort: "分拣线",
            LinearSort: "自动线",
            LoopSort: "环形线",
            ManualSort: "人工线",
            Total: "合计",
            Pcs: "件数",
            Weight: "重量(KG)",
            TotalHandledPcs: "分拣总件数",
            TotalHandledWeight: "分拣总重量",
            AveragePcsBag: "平均每袋件数",
            AveragePackageWeight: "包裹平均重量",
            Week: "周汇总",
            Month: "月汇总",
            Year: "年汇总"
        },
        period2: {
            TruckNo: "车牌号",
            TruckCons: "车CONS号",
            PackageType: "货物类型",
            Pcs: "件数",
            ArrivalTime: "到达时间",
            StartTime: "开始分拣时间",
            EndTime: "分拣结束时间",
            DataException: "异常数据",
            Recorded: "系统有数据",
            NoRecord: "无记录",
            Ratio: "百分比",
            WeightVolumeException: "重量/体积异常",
            Weight: "称重",
            CubicVolume: "体积",
            SortingExceptionRatio: "Belt尾货占比",
            SystemDefault: "满10",
            ManualPause: "暂停",
            Timeout: "超时",
            Others: "其他",
            Secondary: "Secondary",
            BarcodeReadRate: "条码读取率",
            Standard: "达标线"
        }
    }
}