/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat = '{y}-{m}-{d} {h}:{i}:{s}') {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = cFormat
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string')) {
            if ((/^[0-9]+$/.test(time))) {
                // support "1548221490638"
                time = parseInt(time)
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/')
            }
        }

        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        return value.toString().padStart(2, '0')
    })
    return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000

    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
    if (!search) {
        return {}
    }
    const obj = {}
    const searchArr = search.split('&')
    searchArr.forEach(v => {
        const index = v.indexOf('=')
        if (index !== -1) {
            const name = v.substring(0, index)
            const val = v.substring(index + 1, v.length)
            obj[name] = val
        }
    })
    return obj
}

// 表单重置
export function resetForm(refName) {
    if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
    }
}

//引入decimal.js
import Decimal from "decimal.js";
// 设置精度
Decimal.set({precision: 20});

export const math = {
    add,
    subtract,
    multiply,
    divide,
};

// 加法运算
function add(num1, num2) {
    const decimalNum1 = new Decimal(num1);
    const decimalNum2 = new Decimal(num2);
    return decimalNum1.plus(decimalNum2).toString();
}

// 减法运算
function subtract(num1, num2) {
    const decimalNum1 = new Decimal(num1);
    const decimalNum2 = new Decimal(num2);
    return decimalNum1.minus(decimalNum2).toString();
}

// 乘法运算
function multiply(num1, num2) {
    const decimalNum1 = new Decimal(num1);
    const decimalNum2 = new Decimal(num2);
    return decimalNum1.times(decimalNum2).toString();
}

// 除法运算
function divide(num1, num2) {
    if (!num1 || !num2) {
        return '0'
    }
    const decimalNum1 = new Decimal(num1);
    const decimalNum2 = new Decimal(num2);
    return decimalNum1.dividedBy(decimalNum2).toString();
}

/**
 * 生成百分数
 * @param {Number|any} molecule 分子
 * @param {Number|any} denominator 分母
 * @param {Object} options 可选项
 * @param {Boolean} isToFixed 是否需要小数
 * @param {Number|Boolean} decimalPlaces 小数位数
 * @param {Boolean} percentSign 是否显示百分号
 * @return {String} percentage
 */
export function createPercentage(molecule, denominator, options = {}) {
    const {isToFixed = true, decimalPlaces = 2, percentSign = true} = options;
    let percentage; // 百分比
    let _get = _getPercentage(percentSign);
    if (typeof molecule !== "number") {
        molecule = Number(molecule);
        if (Object.is(molecule, NaN)) return _get(0);
    }
    if (typeof denominator !== "number") {
        denominator = Number(denominator);
        if (Object.is(denominator, NaN)) return _get(0);
    }
    if (denominator === 0 || molecule === 0) return _get(0);
    percentage = math.divide(molecule, denominator);
    percentage = math.multiply(percentage, 100);
    if (isToFixed) {
        percentage = Number(percentage).toFixed(decimalPlaces);
    }
    return _get(percentage);
}

/**
 * 创建函数：是否需要百分号
 * @param {Boolean} percentSign
 * @return {Function}
 */
function _getPercentage(percentSign) {
    return percentSign ? (value) => `${value}%` : (value) => `${value}`;
}

/*获取token*/
export function getToken() {
    const token = localStorage.getItem("dewhd_token");
    return token ? token : null;
}

/*获取用户信息*/
export function getUserInfo(key) {
    let info = null;
    const userInfo = localStorage.getItem("dewhd_userInfo");
    if (key && userInfo) {
        info = JSON.parse(userInfo)[key];
    }
    return info;
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate = false) {
    let timeout, args, context, timestamp, result

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args)
                if (!timeout) context = args = null
            }
        }
    }

    return function (...args) {
        context = this
        timestamp = +new Date()
        const callNow = immediate && !timeout
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait)
        if (callNow) {
            result = func.apply(context, args)
            context = args = null
        }

        return result
    }
}

/**
 * url参数解析
 * @return {Object} 参数
 */
export function getURLParams() {
    const searchParams = new URLSearchParams(window.location.hash.split("?")[1]);
    const params = {};

    for (let [key, value] of searchParams.entries()) {
        params[key] = value;
    }
    return params;
}


/**
 * 计算两个时间相差小时数
 * @param {String} time1 开始时间，例如：2023-10-11 10:00:00
 * @param {String} time2 结束时间，例如：2023-10-11 14:30:00
 * @return {Number} 小时数 例如：4
 */
export function calculateHours(time1, time2) {
    const date1 = new Date(time1);
    const date2 = new Date(time2);

    const diff = Math.abs(date1.getTime() - date2.getTime());
    return Math.floor(diff / (1000 * 60 * 60));
}

/**
 * 计算百分比，默认两位小数
 * @param {String} num 数字
 * @param {String} total 总数
 * @return {*} any
 */
export const calculateProportion = (num, total) => {
    if (!total || Number(total) === 0) return 0;
    if (!num || Number(num) === 0) return 0;
    let proportion = math.divide(num, total);
    // return math.multiply(proportion, 100).toFixed(2)
    const sum = math.multiply(proportion, 100);
    return roundNumber(sum, 2);
};

/**
 * 字符串转数字
 * @param {String} str
 * @return {*} any
 */
export function getNumber(num) {
    return isNaN(Number(num)) ? 0 : Number(num);
}

/**
 * 截取小数位数
 * @param {Number} number 要截取的数字
 * @param {Number} decimals 小数位数
 * @return {Number} 截取后的数字
 */
export function roundNumber(number, decimals = 2) {
    const factor = 10 ** decimals;
    return Math.round(number * factor) / factor;
}

/**
 *mathjs中的运算【加】方法
 *@param {number} num1
 *@param {number} num2
 *
 * */
export function mathAdd(num1, num2) {
    return math.add(num1, num2)
}

/**
 * 获取当前日期的前某天的时间
 * @param {number} num 几天前
 * @param {string} cFormat 日期格式
 * @return {String} 时间
 */
export const getOldTime = (num = 0, cFormat = '{y}-{m}-{d} {h}:{i}:{s}') => {
    const date = new Date();
    if (num) {
        date.setTime(date.getTime() - 3600 * 1000 * 24 * num);
    }
    return parseTime(date, cFormat)
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 获取用户设置的时间
 * @param {String} routePath 路由路径
 * @return {Object} {taskTimeStart, taskTimeEnd}
 */
export function getTaskTime(routePath = '') {
    if (routePath) {
        const key = `taskTime${routePath.replaceAll('/', '-')}`
        let taskTime = localStorage.getItem(key)
        if (taskTime) {
            taskTime = JSON.parse(taskTime)
            return taskTime
        }
    }
    return {taskTimeStart: '00:00:00', taskTimeEnd: '23:59:00'}
}

/**
 * 获取当前看板初始化时间
 * @param {number} num 距离当前时间相隔多少天数
 * @param {string} routePath 路由路径
 * @return {*} any
 */
export function getInitTime(num, routePath) {
    let taskTimeStart = getOldTime(num, '{y}-{m}-{d}')
    let taskTimeEnd = parseTime(new Date(), '{y}-{m}-{d}')
    const time = getTaskTime(routePath)
    return {
        taskTimeStart: `${taskTimeStart} ${time.taskTimeStart}`,
        taskTimeEnd: `${taskTimeEnd} ${time.taskTimeEnd}`
    }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}






/**
 * 获取用户设置的时间
 * @param {String} routePath 路由路径
 * @return {Object} {taskTimeStart, taskTimeEnd}
 */
export function getselectTime(routePath = '') {
    if (routePath) {
        const key = `taskTime${routePath.replaceAll('/', '-')}`
        let taskTime = localStorage.getItem(key)
        // console.log(taskTime);
        if (taskTime) {
            taskTime = JSON.parse(taskTime)
            return taskTime
        }
    }
    return {taskTimeStart: '21:00:00', taskTimeEnd: '21:00:00'}
}

export function getInitTim(num, routePath) {
    let taskTimeStart = getOldTime(num, '{y}-{m}-{d}')
    let taskTimeEnd = parseTime(new Date(), '{y}-{m}-{d}')
    const time = getselectTime(routePath)
    // console.log(time)

    return {
        taskTimeStart: `${taskTimeStart} ${time.taskTimeStart}`,
        taskTimeEnd: `${taskTimeEnd} ${time.taskTimeEnd}`
    }

}