import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import zhLocale from "./zh"
import enLocale from "./en"
import zhFedEx from "./fedEx/zh"
import enFedEx from "./fedEx/en"
import zhKanBan from './kanBan/zh'
import enKanBan from './kanBan/en'
import {getURLParams} from "@/utils";

Vue.use(VueI18n)

const messages = {
  en: {
    ...elementEnLocale,
    ...enFedEx,
    ...enLocale,
    ...enKanBan
  },
  zh: {
    ...elementZhLocale,
    ...zhFedEx,
    ...zhLocale,
    ...zhKanBan
  },
}

// 获取当前语言
export function getLanguage() {
  const urlParams = getURLParams()
  if (urlParams && urlParams.lang) {
    localStorage.setItem("fedExlocale", urlParams.lang);
    return urlParams.lang === 'en' ? 'en' : 'zh'
  }

  let localeStr = localStorage.getItem("fedExlocale");
  if (localeStr) return localeStr

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = new VueI18n({
  locale: getLanguage() || 'zh',
  // locale: 'zh',
  messages,
  silentTranslationWarn: true
})

export default i18n
