import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './lang/index'
import store from './store'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-variables.scss'
import "@/styles/reset.css";

Vue.config.productionTip = false

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// 数字滚动组件
import countTo from 'vue-count-to'
Vue.component('CountTo', countTo)

// echarts组件
import ChartBlock from '@/components/ChartBlock.vue'
Vue.component('ChartBlock', ChartBlock)

// mCard组件
import mCard from "@/components/mCard.vue";
Vue.component('mCard', mCard)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
