<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 宽度
    width: {
      type: String,
      default: 'fit-content'
    },
    // 高度
    height: {
      type: String,
      default: 'fit-content'
    },
    clipPath: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    setClipPath(clipPath) {
      let val = ''
      if (clipPath && clipPath.length) {
        const start = clipPath[0]
        const end = clipPath[1]
        if (start === 'left' && end === 'top') {
          val = 'setClipPath_right_top'
        } else if (start === 'left' && end === 'bottom') {
          val = 'setClipPath_right_top'
          val = ''
        } else if (start === 'right' && end === 'top') {
          val = 'setClipPath_right_top'
        } else if (start === 'right' && end === 'bottom') {
          val = 'setClipPath_right_top'
        }
      }
      return val
    }
  }
}

</script>

<template>
  <div
    class="mCard"
    :style="{
      width: width,
      height: height,
    }"
    :class="setClipPath(clipPath)"
  >
    <div v-if="title" class="card-title">
      <div class="bg-img icon" />
      <p class="title-text">{{ title }}</p>
    </div>
    <slot />
  </div>
</template>

<style scoped lang="scss">
.mCard {
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 3px 14px 1px rgba(75, 75, 75, 0.12);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  //align-items: center;
  flex-direction: column;

  .card-title {
    display: flex;
    align-items: center;
    padding: 16px 0;
    margin-left: 40px;
  }

  .icon {
    margin-right: 6px;
	  margin-bottom: 3px;
    width: 18px;
    height: 18px;
    background-size: 100% 100%;
    background-image: url("~@/assets/icons/icon-cardTitle.png");
  }

  .title-text {
    font-size: 28px;
    font-weight: normal;
    color: #4d148c;
	  font-family: "FedExSans_XBd", "Microsoft YaHei", serif;
  }
}
</style>
